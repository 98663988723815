/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
//import appsAndPages from './apps-and-pages'
import store from "@/store/index";
let pages = null;

pages = [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "Collection Calendar",
    route: "calendar",
    icon: "CalendarIcon",
  },
  {
    title: "Invoices",
    route: { name: "invoices" },
    icon: "FileTextIcon",
  },
  {
    title: "Documents",
    icon: "FileTextIcon",
    route: { name: "documents" },
  },
  {
    title: "Notifications",
    icon: "MessageSquareIcon",
    route: { name: "notifications" },
  },
  {
    title: "Knowledge Base",
    icon: "SearchIcon",
    route: { name: "news-knowledgebase" },
  },
  {
    title: "News",
    icon: "GlobeIcon",
    route: { name: "news-waste" },
  },
  {
    title: "Contact",
    route: "contact",
    icon: "MailIcon",
  },
  {
    title: "Marketplace ★",
    route: "marketplace",
    icon: "ShoppingCartIcon",
  },
];

// if (store.state.app.selectedUserLevels == "354510000") {
//   pages = [
//     {
//       title: "Dashboard",
//       route: "dashboard",
//       icon: "HomeIcon",
//     },
//     {
//       title: "Collection Calendar",
//       route: "calendar",
//       icon: "CalendarIcon",
//     },
//     {
//       title: "Invoices",
//       route: { name: "invoices" },
//       icon: "FileTextIcon",
//     },
//     {
//       title: "Documents",
//       icon: "FileTextIcon",
//       route: { name: "documents" },
//     },
//     {
//       title: "Notifications",
//       icon: "MessageSquareIcon",
//       route: { name: "notifications" },
//     },
//     {
//       title: "Knowledge Base",
//       icon: "SearchIcon",
//       route: { name: "news-knowledgebase" },
//     },
//     {
//       title: "News",
//       icon: "GlobeIcon",
//       route: { name: "news-waste" },
//     },
//     {
//       title: "Contact",
//       route: "contact",
//       icon: "MailIcon",
//     },
//     {
//       title: "Marketplace",
//       route: "marketplace",
//       icon: "ShoppingCartIcon",
//     },
//   ];
// } else {
//   pages = [
//     {
//       title: "Dashboard",
//       route: "dashboard",
//       icon: "HomeIcon",
//     },
//     {
//       title: "Collection Calendar",
//       route: "calendar",
//       icon: "CalendarIcon",
//     },
//     {
//       title: "Notifications",
//       icon: "MessageSquareIcon",
//       route: { name: "notifications" },
//     },
//     {
//       title: "Knowledge Base",
//       icon: "SearchIcon",
//       route: { name: "news-knowledgebase" },
//     },
//     {
//       title: "News",
//       icon: "GlobeIcon",
//       route: { name: "news-waste" },
//     },
//     {
//       title: "Contact",
//       route: "contact",
//       icon: "MailIcon",
//     },
//     {
//       title: "Marketplace",
//       route: "marketplace",
//       icon: "ShoppingCartIcon",
//     },
//   ];
// }

export default pages;
